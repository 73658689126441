



















































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import TemplateComponent from '@/components/TemplateComponent.vue'
import { vxm } from '@/store'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import Tags from '@/components/tag/Tags.vue'

@Component({
  components: { Tags, DocumentDialog, TemplateComponent, PageTab, ListView },
})
export default class List2 extends Vue {
  private listViewKey = 0
  private loading = false

  private topActions = []
  private rowActions = []
  private headers = []
  private filteringLocations = []
  private locations = []
  private tyreCounters = []

  private detailedData = false
  private blankCertificateDialog = false

  private document = null
  private location = null
  private locationType = 'wheelChangeLocation'

  private report = ''
  private reports = []

  async created() {
    this.loading = true
    await this.loadAdditionalData()
    this.setInitialHeaders()
    this.setRowActions()
    this.setTopActions()
  }

  private async loadAdditionalData() {
    this.loading = true

    try {
      const [tyreCounterResponse, locationsResponse, defaultLocationResponse, reports] = await Promise.all([
        this.$axios.get('/v4/site/tyre-hotels-v2/statistics'),
        this.$axios.get('/v4/site/locations?includeMyEmpireSites=true'),
        this.$axios.get('/v4/site/locations/wheel-change-default'),
        this.$axios.get('/v4/site/reports'),
      ])

      this.tyreCounters = tyreCounterResponse.data.data

      this.locations = locationsResponse.data.data
      this.filteringLocations = locationsResponse.data.data.map((location) => ({
        id: location.id,
        name: location.name + ' (' + location.code + ')',
      }))

      const allOption = { id: 0, name: 'All' }
      this.filteringLocations.unshift(allOption)

      this.location = defaultLocationResponse.data.data.locationId

      this.reports = reports.data.data.data
    } catch (error) {
      await vxm.alert.onAxiosError(error, 'Error loading initial data')
    }

    this.loading = false
  }

  private openReport() {
    this.$router.push({
      name: 'Reports/Generic',
      params: { name: this.report },
    })
  }

  private setRowActions() {
    this.rowActions = [
      {
        id: 'view',
        route: (item) => {
          return {
            name: 'TyreHotel/View',
            params: { tyreHotelId: item.id },
          }
        },
      },
    ]
  }

  private setTopActions() {
    this.topActions = [
      {
        id: 'new',
        template: 'new',
        label: 'New tyre hotel',
        route: () => {
          return {
            name: 'TyreHotel/New',
          }
        },
      },
    ]
  }

  private blankCertificate() {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelCertificateBlank'
    this.document.params = {}
    this.blankCertificateDialog = true
  }

  get url() {
    return '/v4/site/tyre-hotels-v2'
  }

  private detailed(detailed) {
    this.detailedData = detailed
    detailed ? this.setDetailedHeaders() : this.setInitialHeaders()
  }

  private formatBrandName(brandName, model) {
    return `${brandName} ${model}`
  }

  // todo
  private formatTreadDepth(item) {
    const mmValues = item.tyreHotelLines
      .map((tyreLine) => tyreLine.mm)
      .filter((mm) => mm !== null && mm !== undefined)
    return mmValues.length > 0 ? mmValues.join(', ') : ''
  }

  // todo
  private formatIsStudded(isStudded) {
    return isStudded === 1 ? this.$t('c:common:Yes') : this.$t('c:common:No')
  }

  private setInitialHeaders() {
    this.headers = [
      {
        text: 'Tyre hotel ID',
        value: 'id',
      },
      {
        text: 'License plate',
        value: 'car.licenseplate',
      },
      {
        text: 'Car model',
        value: 'carName',
      },
      {
        text: 'Product',
        value: 'product.eontyreDescription',
      },
      {
        text: 'Customer',
        value: 'car.customer.name',
      },
      {
        text: 'Driver',
        value: 'car.driver.name',
      },
      {
        text: 'Shop Location',
        value: 'wheelChangeLocation.name',
        filter: {
          items: this.locations,
          itemText: 'name',
          itemValue: 'id',
        },
        sortable: false,
      },
      {
        text: 'c:tyre-hotel-list:Position',
        value: 'positions',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'In stock',
        value: 'inStockSeasons',
        filter: {
          equalsOperatorOnly: true,
          multiple: false,
          items: [
            { text: this.$t('c:common:Summer'), value: 1 },
            { text: this.$t('c:common:Winter'), value: 2 },
          ],
        },
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]
  }

  private setDetailedHeaders() {
    this.setInitialHeaders()
    this.headers.splice(
      8,
      0,
      {
        text: 'Comment',
        value: 'internalComment',
      },
      {
        text: 'Status',
        value: 'status',
        filter: {
          items: [
            { text: this.$t('Terminated'), value: 0 },
            { text: this.$t('Active'), value: 1 },
          ],
        },
      },
      {
        text: 'Tags',
        value: 'tags',
        sortable: false,
        filter: {
          disable: true,
        },
      },
      /*
      not ready yet
      {
        text: 'c:tyre-hotel-list:Tread Depths',
        value: 'tyreHotelLines.mm',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'c:tyre-hotel-list:Is studded',
        value: 'isStudded',
        filter: { disable: true },
        sortable: false,
      },
       */
    )
  }

  private get query() {
    return {
      location: this.location,
      locationType: this.locationType,
      detailed: this.detailedData,
    }
  }
}
